import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import HomeHero from '../components/Home/HomeHero'
import HomeBenefits from '../components/Home/HomeBenefits'
// import HomeTestimonials from '../components/Home/HomeTestimonials'
import Footer from '../components/Footer'
import HomeCompare from '../components/Home/HomeCompare'
import HomeJoin from '../components/Home/HomeJoin'
import HomeContact from '../components/Home/HomeContact'
import HomeSteps from '../components/Home/HomeSteps'
import HomeTabs from '../components/Home/HomeTabs'

const IndexPage = ({ data }) => {
  const { routes, siteLocale } = data.site.siteMetadata
  const {
    seoTitle,
    seoDescription: { seoDescription },
    heroWords,
    heroText,
    benefitsTitle,
    benefitsImage,
    benefitsText: { benefitsText },
    benefitsLabel,
    benefits,
    stepsTitle,
    steps,
    testimonialsTitle,
    doctorsTestimonialLabel,
    doctorsTestimonial,
    doctorsTestimonialVideoId,
    doctorsTestimonialVideoOverlay,
    patientsTestimonialLabel,
    patientsTestimonial,
    patientsTestimonialVideoId,
    patientsTestimonialVideoOverlay,
    successStoriesLinkText,
    compareTitle,
    compareText,
    comparisonsLinkText,
    compareTable,
    joinTitle,
    joinText: { joinText },
    joinLinkText,
    joinImages,
    contactFormTitle,
  } = data.contentfulHome

  const testimonialTabs = [
    {
      label: doctorsTestimonialLabel,
      quote: doctorsTestimonial,
      videoId: doctorsTestimonialVideoId,
      videoThumbnail: doctorsTestimonialVideoOverlay,
    },
    {
      label: patientsTestimonialLabel,
      quote: patientsTestimonial,
      videoId: patientsTestimonialVideoId,
      videoThumbnail: patientsTestimonialVideoOverlay,
    },
  ]

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />

      <HomeHero words={heroWords} text={heroText} />

      <HomeBenefits
        title={benefitsTitle}
        text={benefitsText}
        label={benefitsLabel}
        image={benefitsImage}
        items={benefits}
      />

      <HomeSteps
        title={stepsTitle}
        steps={steps}
        route={routes.howItWorks}
        routeTitle={data.contentfulHowItWorks.seoTitle}
      />

      {/* <HomeTestimonials
        title={testimonialsTitle}
        tabs={testimonialTabs}
        cta={successStoriesLinkText}
        ctaUrl={routes.successStories}
      /> */}

      <HomeTabs
        title={testimonialsTitle}
        tabs={testimonialTabs}
        cta={successStoriesLinkText}
        ctaUrl={routes.successStories}
      />

      <HomeCompare
        title={compareTitle}
        text={compareText}
        link={routes.compare}
        linkText={comparisonsLinkText}
        table={compareTable.table.tableData}
      />

      {/* {(siteLocale === 'es' || siteLocale === 'en-US') && (
        <HomeJoin
          title={joinTitle}
          text={joinText}
          linkText={joinLinkText}
          link={routes.academy}
          images={joinImages}
        />
      )} */}

      <HomeContact title={contactFormTitle} />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteLocale
        routes {
          compare
          successStories
          academy
          howItWorks
        }
      }
    }
    contentfulHome {
      seoTitle
      seoDescription {
        seoDescription
      }
      heroWords
      heroText
      benefitsTitle
      benefitsImage {
        gatsbyImageData(quality: 75)
        description
      }
      benefitsText {
        benefitsText
      }
      benefitsLabel
      benefits
      stepsTitle
      steps {
        stepNumber
        title
        title1 {
          raw
        }
        text {
          text
        }
        nextStepText
        image {
          gatsbyImageData
        }
        videoId
      }
      testimonialsTitle
      doctorsTestimonialLabel
      doctorsTestimonial
      doctorsTestimonialVideoId
      doctorsTestimonialVideoOverlay {
        gatsbyImageData
      }
      patientsTestimonialLabel
      patientsTestimonial
      patientsTestimonialVideoId
      patientsTestimonialVideoOverlay {
        gatsbyImageData
      }
      successStoriesLinkText
      compareTitle
      compareText
      comparisonsLinkText
      compareTable {
        table {
          tableData
        }
      }
      joinTitle
      joinText {
        joinText
      }
      joinLinkText
      joinImages {
        gatsbyImageData(quality: 75)
        description
      }
      contactFormTitle
    }
    contentfulHowItWorks {
      seoTitle
    }
  }
`

export default IndexPage
