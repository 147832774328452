import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'

gsap.registerPlugin(GSDevTools)

const IconMouse = () => {
  const circleRefs = useRef([])

  useEffect(() => {
    const timeline = gsap.timeline({
      id: 'mouse',
      repeat: -1,
      repeatDelay: 1,
      defaults: {
        ease: 'power2.inOut',
      },
    })

    const inDur = 0.5
    const stagger = inDur * 0.3

    timeline
      .to(circleRefs.current, {
        opacity: 1,
        stagger: stagger,
        duration: inDur,
      })
      .to(
        circleRefs.current,
        {
          opacity: 0.4,
          stagger: stagger,
          duration: inDur * 0.8,
        },
        inDur * 0.9
      )

    // GSDevTools.create({ css: 'z-index: 100', id: 'mouse' })
  }, [])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 35"
      aria-hidden="true"
      focusable="false"
      fill="none"
      width="17"
      height="35"
    >
      <circle
        ref={ref => (circleRefs.current[0] = ref)}
        opacity="0.4"
        cx="8.5"
        cy="8.5"
        r="1.5"
        fill="#F0F1F2"
      />
      <circle
        ref={ref => (circleRefs.current[1] = ref)}
        opacity="0.4"
        cx="8.5"
        cy="14.5"
        r="1.5"
        fill="#F0F1F2"
      />
      <circle
        ref={ref => (circleRefs.current[2] = ref)}
        opacity="0.4"
        cx="8.5"
        cy="20.5"
        r="1.5"
        fill="#F0F1F2"
      />
      <circle
        ref={ref => (circleRefs.current[3] = ref)}
        opacity="0.4"
        cx="8.5"
        cy="26.5"
        r="1.5"
        fill="#F0F1F2"
      />
      <rect
        opacity="0.4"
        x=".5"
        y=".5"
        width="16"
        height="34"
        rx="8"
        stroke="#F0F1F2"
      />
    </svg>
  )
}

export default IconMouse
