import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'
import { SplitText } from 'gsap/SplitText'
import ScrollSection from '../../ScrollSection'
import Spacer from '../../Spacer'
import { animation } from '../../../styles/vars/animation.style'
import SceneOrthographic from '../../SceneOrthographic'
import { Title } from '../../TextStyles'
import IconMouse from '../../svgs/IconMouse'
import AnimateSplitText from '../../animation/AnimateSplitText'
import {
  HomeHeroContent,
  HomeHeroMain,
  HomeHeroMouse,
  HomeHeroTitle,
  Word,
} from './index.style'
import { colors } from '../../../styles/vars/colors.style'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(GSDevTools)

const HomeHero = ({ words, text, debug }) => {
  const wordRefs = useRef([])
  const [showText, setShowText] = useState(!debug)
  const loadDelay = animation.maskHideDuration + 0.5

  useEffect(() => {
    const wordChars = []
    const tl = {
      startDelay: loadDelay,
      wordDelay: 0.5,
      duration: 0.5,
    }
    const timeline = gsap.timeline({
      repeat: -1,
      paused: true,
      delay: tl.startDelay,
    })

    wordRefs.current.forEach((word, wordIndex) => {
      const mainSplit = new SplitText(word.main, {
        type: 'chars',
      })

      wordChars[wordIndex] = mainSplit.chars
    })

    gsap.registerEffect({
      name: 'exit',
      effect: targets => {
        return gsap.fromTo(
          targets,
          {
            opacity: 1,
            yPercent: 0,
            rotateX: 0,
          },
          {
            opacity: 0,
            yPercent: -100,
            rotateX: 90,
            ease: 'power3.in',
            duration: tl.duration,
            stagger: 0.01,
            delay: tl.wordDelay,
            immediateRender: false,
          }
        )
      },
      extendTimeline: true,
    })

    gsap.registerEffect({
      name: 'enter',
      effect: targets => {
        return gsap.fromTo(
          targets,
          {
            opacity: 0,
            yPercent: 100,
            rotateX: -90,
            transformOrigin: '0% 0%',
          },
          {
            opacity: 1,
            yPercent: 0,
            rotateX: 0,
            ease: 'power3.out',
            duration: tl.duration,
            stagger: 0.01,
            immediateRender: false,
          }
        )
      },
      extendTimeline: true,
    })

    wordRefs.current.forEach((word, wordIndex) => {
      const enterStart = wordIndex === 0 ? 0 : '<0.3'

      if (wordIndex > 0) {
        timeline.exit(wordChars[wordIndex - 1], wordIndex * 3)
      }

      timeline.enter(wordChars[wordIndex], enterStart)

      if (wordIndex === wordChars.length - 1) {
        timeline.exit(wordChars[wordIndex], `<${tl.wordDelay + tl.duration}`)
      }
    })

    timeline.play()
    // GSDevTools.create({ css: 'z-index: 100' })

    return () => {
      timeline.kill()
    }
  }, [loadDelay])

  return (
    <ScrollSection>
      <>
        <HomeHeroMain>
          <SceneOrthographic debug={debug} />

          <HomeHeroContent>
            <HomeHeroTitle
              show={showText}
              aria-label="Precision, Control, Sensitivity"
            >
              {React.Children.toArray(
                words.map((word, wordIndex) => (
                  <Word
                    ref={ref => {
                      wordRefs.current[wordIndex] = {
                        main: ref,
                      }
                    }}
                    aria-hidden
                  >
                    {word}
                  </Word>
                ))
              )}
            </HomeHeroTitle>

            <Spacer size={[12, 16]} />

            {showText && (
              <Title as="h2">
                <AnimateSplitText type="lines,words" delay={0.2}>
                  {text}
                </AnimateSplitText>
              </Title>
            )}
          </HomeHeroContent>

          {debug && (
            <button
              onClick={() => {
                setShowText(!showText)
              }}
              style={{
                position: 'absolute',
                bottom: '5rem',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '0.5em 1em',
                color: colors.light,
                backgroundColor: colors.darkGrey,
                borderRadius: '0.8em',
                border: '1px solid rgba(255,255,255,0.3)',
                zIndex: 2000,
              }}
            >
              {showText ? 'Hide text' : 'Show text'}
            </button>
          )}

          <HomeHeroMouse>
            <IconMouse />
          </HomeHeroMouse>
        </HomeHeroMain>
      </>
    </ScrollSection>
  )
}

HomeHero.propTypes = {
  words: PropTypes.array,
  text: PropTypes.string,
  debug: PropTypes.bool,
}

export default HomeHero
