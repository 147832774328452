import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { ContactFormMain } from '../../ContactForm/index.style'

export const HomeContactMain = styled.div`
  position: relative;
  overflow: hidden;
  border-top: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
`

export const HomeContactMonogram = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 25vw;
  height: 100%;
  opacity: 0.15;

  ${mq.tabletP} {
    display: block;
  }

  svg {
    position: relative;
    top: 50%;
    transform: translate(-13.6%, -41%);
  }
`

export const HomeContactForm = styled.div`
  ${ContactFormMain} {
    ${mq.tabletP} {
      grid-template-areas:
        'type type type name name name'
        'email email phone phone country country'
        'speciality speciality speciality speciality speciality speciality'
        'position position position position position position'
        'message message message message message message'
        'legal legal legal legal legal legal'
        'marketing marketing marketing marketing marketing marketing'
        'submit submit submit submit submit submit';
    }
  }
`
