import React from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { CarouselDotsMain, CarouselDotsItem } from './index.style'

const CarouselDots = ({ theme, slides, activeIndex }) => (
  <CarouselDotsMain theme={theme}>
    {React.Children.toArray(
      slides.map((item, itemIndex) => (
        <CarouselDotsItem
          theme={theme}
          position={gsap.utils.clamp(0, 3, Math.abs(itemIndex - activeIndex))}
        />
      ))
    )}
  </CarouselDotsMain>
)

CarouselDots.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  slides: PropTypes.array,
  activeIndex: PropTypes.number,
}

CarouselDots.defaultProps = {
  theme: 'light',
}

export default CarouselDots
