import React, { useContext, useEffect, useState } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import {
  HomeTabsTestimonial,
  HomeTabsContent,
  HomeTabsMain,
  HomeTabsButton,
  HomeTabsList,
  HomeTabsItems,
  HomeTabsActive,
} from './index.style'
import ScrollSection from '../../ScrollSection'
import Container from '../../Container'
import { Heading2, TitleSmall } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { colors } from '../../../styles/vars/colors.style'
import Spacer from '../../Spacer'
import Cta from '../../Cta'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import VimeoPlayer from '../../VimeoPlayer'
import { useRef } from 'react'
import { StoreState } from '../../../Store'

const HomeTabs = ({ title, tabs, cta, ctaUrl }) => {
  const store = useContext(StoreState)
  const [activeTabButtonIndex, setActiveTabButtonIndex] = useState(0)
  const activeTabButtonRef = useRef(0)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const $tabs = useRef([])
  const $buttons = useRef([])
  const buttonDimensions = useRef([])
  const $tabsWrapper = useRef()
  const $activeRect = useRef()

  const animateActiveRect = () => {
    gsap.to($activeRect.current, {
      width: buttonDimensions.current[activeTabButtonRef.current].width,
      x: buttonDimensions.current[activeTabButtonRef.current].left,
      opacity: 1,
      duration: 0.3,
      ease: 'power3.inOut',
    })
  }

  useEffect(() => {
    if (!store.fontIsLoaded) return

    const setDimensions = () => {
      $buttons.current.forEach((btn, btnIndex) => {
        const rect = btn.getBoundingClientRect()

        buttonDimensions.current[btnIndex] = {
          width: rect.width,
          left: rect.left,
        }
      })

      animateActiveRect()
    }

    setDimensions()

    window.addEventListener('resize', setDimensions)

    return () => {
      window.removeEventListener('resize', setDimensions)
    }
  }, [store.fontIsLoaded])

  useEffect(() => {
    activeTabButtonRef.current = activeTabButtonIndex

    if (buttonDimensions.current.length) {
      animateActiveRect()
    }
  }, [activeTabButtonIndex])

  const updateTab = nextIndex => {
    if (nextIndex === activeTabIndex) return

    setActiveTabButtonIndex(nextIndex)

    setActiveTabIndex(-1)

    gsap.to($tabsWrapper.current, {
      height: $tabs.current[nextIndex].offsetHeight,
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        gsap.set($tabs.current[nextIndex], {
          position: 'relative',
        })
        gsap.set($tabs.current[activeTabIndex], {
          position: 'absolute',
        })
        setActiveTabIndex(nextIndex)
        gsap.set($tabsWrapper.current, {
          clearProps: 'height',
        })

        if (window.scroll) {
          window.scroll.update()
        }
      },
    })
  }

  return (
    <HomeTabsMain>
      <ScrollSection>
        <>
          <Container>
            <Heading2 color={colors.blue}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading2>

            <Spacer size={[24, 70]} />
          </Container>

          <HomeTabsContent>
            <HomeTabsList>
              {React.Children.toArray(
                tabs.map((tab, tabIndex) => (
                  <HomeTabsButton
                    open={activeTabButtonIndex === tabIndex}
                    onClick={() => updateTab(tabIndex)}
                  >
                    <TitleSmall ref={ref => ($buttons.current[tabIndex] = ref)}>
                      <AnimateSplitText>{tab.label}</AnimateSplitText>
                    </TitleSmall>
                  </HomeTabsButton>
                ))
              )}

              <HomeTabsActive ref={$activeRect} />
            </HomeTabsList>

            <HomeTabsItems ref={$tabsWrapper}>
              {React.Children.toArray(
                tabs.map((tab, tabIndex) => (
                  <HomeTabsTestimonial
                    ref={ref => ($tabs.current[tabIndex] = ref)}
                    active={activeTabIndex === tabIndex}
                    aria-hidden={activeTabIndex !== tabIndex}
                  >
                    <Container>
                      <Spacer size={[48, 120]} />

                      <Grid>
                        <GridItem desk={6} deskStart={4}>
                          <AnimateFadeIn animate={activeTabIndex === tabIndex}>
                            <VimeoPlayer
                              id={tab.videoId}
                              thumbnail={tab.videoThumbnail}
                              endPlaying={!(activeTabIndex === tabIndex)}
                            />
                          </AnimateFadeIn>

                          <Spacer size={36} />

                          <Heading2>
                            <AnimateSplitText
                              animate={activeTabIndex === tabIndex}
                            >
                              {`“${tab.quote}”`}
                            </AnimateSplitText>
                          </Heading2>
                        </GridItem>
                      </Grid>
                    </Container>
                  </HomeTabsTestimonial>
                ))
              )}
            </HomeTabsItems>

            <Spacer size={56} />

            <Cta to={ctaUrl} label={cta} color={colors.blue} />

            <Spacer size={[70, 120]} />
          </HomeTabsContent>
        </>
      </ScrollSection>
    </HomeTabsMain>
  )
}

HomeTabs.propTypes = {
  tabs: PropTypes.array,
  title: PropTypes.string,
  cta: PropTypes.string,
  ctaUrl: PropTypes.string,
}

export default HomeTabs
