import styled from 'styled-components'
import { transparentize } from 'polished'
import { colors } from '../../styles/vars/colors.style'

export const CarouselDotsMain = styled.ul`
  display: inline-flex;
  padding: 0.7rem;
  list-style: none;
  border: 0.1rem solid
    ${props =>
      props.theme === 'light'
        ? transparentize(0.84, colors.lightGrey)
        : transparentize(0.84, colors.darkGrey)};
  border-radius: 1rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const CarouselDotsItem = styled.li.attrs(({ position }) => ({
  style: {
    opacity: 1 - position * 0.25,
  },
}))`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: ${props =>
    props.theme === 'light' ? colors.lightGrey : colors.darkGrey};
  transition: opacity 0.2s ease;

  & + & {
    margin-left: 0.3rem;
  }
`
