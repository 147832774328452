import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { TextBody } from '../../../styles/vars/textStyles.style'

export const HomeBenefitsIcon = styled.div`
  position: relative;
  ${clamp('width', 33, 43)}
  margin-left: auto;
  margin-right: auto;
`

export const HomeBenefitsHeader = styled.div`
  text-align: center;
`

export const HomeBenefitsImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  ${mq.tabletPMax} {
    max-width: 33rem;
  }

  ${mq.tabletP} {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 0;
  }
`

export const HomeBenefitsImage = styled.div`
  ${clamp('border-radius', 16, 24)}
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const HomeBenefitsMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: 2rem;

  ${mq.tabletP} {
    margin-top: 0;
    padding-left: 2.4rem;
  }
`

export const HomeBenefitsList = styled.ul`
  list-style: none;
`

export const HomeBenefitsListItem = styled.li`
  ${TextBody} {
    position: relative;
    ${clamp('padding-left', 28, 32)}
    padding-bottom: 0.25em;
  }
`

export const HomeBenefitsListIcon = styled.span`
  position: absolute;
  top: 0.35em;
  left: 0;
  ${clamp('width', 13, 17)}
`
