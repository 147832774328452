import { transparentize } from 'polished'
import styled from 'styled-components'
import { setVh } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { SuperStyles, Title } from '../../../styles/vars/textStyles.style'

export const HomeHeroMain = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  ${setVh('height', 100, true)}
  text-align: center;
  color: ${colors.light};
  background-color: ${colors.dark};

  :before,
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 25%;
    z-index: 1;
    pointer-events: none;
  }

  :before {
    top: 0;
    background: linear-gradient(
      to bottom,
      ${colors.dark},
      ${transparentize(1, colors.dark)}
    );
  }

  :after {
    bottom: 0;
    background: linear-gradient(
      to top,
      ${colors.dark},
      ${transparentize(1, colors.dark)}
    );
  }

  button:focus {
    outline: none;
  }
`

export const HomeHeroContent = styled.div`
  position: relative;
  width: 100%;

  ${Title} {
    margin-left: auto;
    margin-right: auto;

    ${mq.mobileLMax} {
      max-width: 16em;
    }
  }
`

export const HomeHeroTitle = styled.h2`
  pointer-events: none;
  opacity: ${({ show }) => (show ? 1 : 0)};

  &::selection,
  *::selection {
    background: transparent;
  }
`

export const Word = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  ${SuperStyles}
  perspective: 300rem;
  perspective-origin: -10% 50%;

  :first-child {
    position: relative;
  }

  * {
    opacity: 0;
    will-change: transform, opacity;
  }
`

export const HomeHeroMouse = styled.div`
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`
