import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import * as THREE from 'three'
import { isMobile } from 'react-device-detect'
import Stats from 'three/examples/jsm/libs/stats.module'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import ThreeResourceTracker from '../../ThreeResourceTracker'
import { colors } from '../../styles/vars/colors.style'
import { SceneCanvas, SceneWrapper } from './index.style'

const dat = typeof window !== 'undefined' ? require('dat.gui') : null

class SceneOrthographic extends React.Component {
  constructor(props) {
    super(props)

    this.resTracker = new ThreeResourceTracker()
    this.track = this.resTracker.track.bind(this.resTracker)
    this.modelsLoaded = false
    this.stats = null
    this.useMouse = true
    this.debug = this.props.debug
    this.pixelRatioMatch =
      typeof window !== `undefined`
        ? window.matchMedia('screen and (min-resolution: 2dppx)')
        : null
    this.DOM = {
      wrapper: createRef(),
      canvas: createRef(),
    }
    this.settings = {
      mouse: {
        x: 0,
        y: 0,
        strength: 1,
        speed: 0.1,
      },
      camera: {
        zoom: {
          min: 0.15,
          max: 0.2,
        },
      },
      modelA: {
        position: {
          x: {
            min: 0,
            max: -0.11,
          },
          y: {
            min: 0,
            max: 0.53,
          },
          z: 0,
        },
      },
      modelB: {
        position: {
          x: {
            min: 0.28,
            max: 0.38,
          },
          y: {
            min: 2.6,
            max: 1,
          },
          z: -1.3,
        },
      },
    }
  }

  componentDidMount() {
    this.wrapper = this.props.useWindow ? window : this.DOM.wrapper.current

    this.setScene()
    this.setSizes()
    this.setCamera()
    this.setLights()
    this.setLoadingManager()
    this.loadModels()

    if (!isMobile) this.handleUserEvents()

    if (this.debug) {
      this.setControls()
      this.setStats()
    }

    this.setRenderer()
    this.raf = window.requestAnimationFrame(this.renderLoop.bind(this))
  }

  componentWillUnmount() {
    this.resTracker.dispose()
    this.renderer.dispose()
    if (this.debug) this.controls.dispose()
    this.wrapper.removeEventListener('pointermove', this.onPointerMove)

    window.cancelAnimationFrame(this.raf)
    window.removeEventListener('resize', this.resizeHandler)

    try {
      this.pixelRatioMatch.removeEventListener('change', this.resizeHandler)
    } catch (error) {
      try {
        this.pixelRatioMatch.removeListener(this.resizeHandler)
      } catch (secondaryError) {
        console.error(secondaryError)
      }
    }
  }

  setStats() {
    if (typeof document !== 'undefined') {
      this.stats = new Stats()
      document.body.appendChild(this.stats.dom)
    }
  }

  getPixelRatio() {
    return Math.min(window.devicePixelRatio, 2)
  }

  setLoadingManager() {
    this.loadingManager = new THREE.LoadingManager(() => {
      if (this.debug && dat) this.setGUI()
      this.modelsLoaded = true
    })
  }

  setGUI() {
    const gui = new dat.GUI({ width: 360, zIndex: 1000 })

    const mouse = gui.addFolder('Mouse movement')
    mouse.add(this.settings.mouse, 'strength').min(0).max(1).step(0.01)
    mouse.add(this.settings.mouse, 'speed').min(0).max(1).step(0.01)

    const camera = gui.addFolder('Camera')
    camera.add(this.camera, 'zoom', 0.01, 1, 0.01).listen()

    const settings = gui.addFolder('Settings')
    settings
      .add(this.settings.modelA.position.x, 'max', -0.4, 0, 0.01)
      .name('Model A: xMax')
    settings
      .add(this.settings.modelB.position.x, 'max', 0.28, 0.68, 0.01)
      .name('Model B: xMax')
    settings
      .add(this.settings.modelA.position.y, 'max', 0, 1, 0.01)
      .name('Model A: yMax')
    settings
      .add(this.settings.modelB.position.y, 'max', -3.6, -2.6, 0.01)
      .name('Model B: yMax')

    const models = gui.addFolder('Models')
    // models
    //   .add(this.modelA.position, 'x', -3, 3, 0.01)
    //   .name('Model A: x')
    //   .listen()
    models
      .add(this.modelA.position, 'y', -3, 3, 0.01)
      .name('Model A: y')
      .listen()
    // models
    //   .add(this.modelB.position, 'x', -3, 3, 0.01)
    //   .name('Model B: x')
    //   .listen()
    models
      .add(this.modelB.position, 'y', -3, 3, 0.01)
      .name('Model B: y')
      .listen()
    models.open()

    class ColorGUIHelper {
      constructor(object, prop) {
        this.object = object
        this.prop = prop
      }
      get value() {
        return `#${this.object[this.prop].getHexString()}`
      }
      set value(hexString) {
        this.object[this.prop].set(hexString)
      }
    }
    class DegRadHelper {
      constructor(obj, prop) {
        this.obj = obj
        this.prop = prop
      }
      get value() {
        return THREE.MathUtils.radToDeg(this.obj[this.prop])
      }
      set value(v) {
        this.obj[this.prop] = THREE.MathUtils.degToRad(v)
      }
    }

    function addPointLight(light, helper, name, onChangeFn) {
      const folder = gui.addFolder(name)
      folder.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color')
      folder.add(light, 'intensity', 0, 2, 0.01)
      folder.add(light.position, 'x', -10, 10).onChange(onChangeFn)
      folder.add(light.position, 'z', -8, 6).onChange(onChangeFn)
      folder.add(light.position, 'y', -12, 12).onChange(onChangeFn)
      folder.add(light, 'visible').name('Show light')
      folder.add(helper, 'visible').name('Show helper')
    }

    function addSpotLight(light, helper, name, onChangeFn) {
      const folder = gui.addFolder(name)
      folder.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color')
      folder.add(light, 'intensity', 0, 2, 0.01)
      folder.add(light, 'distance', 0, 120, 1).onChange(onChangeFn)
      folder.add(light, 'penumbra', 0, 1, 0.01).onChange(onChangeFn)
      folder
        .add(new DegRadHelper(light, 'angle'), 'value', 0, 90)
        .name('angle')
        .onChange(onChangeFn)
      folder.add(light.position, 'x', -30, 30).onChange(onChangeFn)
      folder.add(light.position, 'z', -30, 30).onChange(onChangeFn)
      folder.add(light.position, 'y', -16, 16).onChange(onChangeFn)
      folder.add(light, 'visible').name('Show light')
      folder.add(helper, 'visible').name('Show helper')
    }

    function updatePointLight(helper) {
      helper.update()
    }

    function updateSpotLight(light, helper) {
      light.target.updateMatrixWorld()
      helper.update()
    }

    updatePointLight(this.PointLightAHelper)
    updatePointLight(this.PointLightBHelper)
    updateSpotLight(this.SpotLightA, this.SpotLightAHelper)
    updateSpotLight(this.SpotLightB, this.SpotLightBHelper)

    addPointLight(
      this.PointLightA,
      this.PointLightAHelper,
      'Point Light A',
      updatePointLight.bind(this, this.PointLightAHelper)
    )

    addPointLight(
      this.PointLightB,
      this.PointLightBHelper,
      'Point Light B',
      updatePointLight.bind(this, this.PointLightBHelper)
    )

    addSpotLight(
      this.SpotLightA,
      this.SpotLightAHelper,
      'Spot Light A',
      updateSpotLight.bind(this, this.SpotLightA, this.SpotLightAHelper)
    )

    addSpotLight(
      this.SpotLightB,
      this.SpotLightBHelper,
      'Spot Light B',
      updateSpotLight.bind(this, this.SpotLightB, this.SpotLightBHelper)
    )
  }

  setScene() {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(colors.dark)

    if (this.debug) {
      this.scene.add(new THREE.AxesHelper())
    }
  }

  updateScene(time) {
    if (!this.useMouse) return

    const absMouseX = Math.abs(this.settings.mouse.x)

    this.camera.zoom = THREE.MathUtils.lerp(
      this.camera.zoom,
      Math.max(
        this.settings.camera.zoom.min,
        absMouseX * this.settings.camera.zoom.max * this.settings.mouse.strength
      ),
      this.settings.mouse.speed
    )

    this.modelA.position.x = THREE.MathUtils.lerp(
      this.modelA.position.x,
      Math.min(
        absMouseX *
          this.settings.modelA.position.x.max *
          this.settings.mouse.strength,
        this.settings.modelA.position.x.min
      ),
      this.settings.mouse.speed
    )

    this.modelA.position.y = THREE.MathUtils.lerp(
      this.modelA.position.y,
      Math.max(
        absMouseX *
          this.settings.modelA.position.y.max *
          this.settings.mouse.strength,
        this.settings.modelA.position.y.min
      ),
      this.settings.mouse.speed
    )

    this.modelB.position.x = THREE.MathUtils.lerp(
      this.modelB.position.x,
      Math.max(
        absMouseX *
          this.settings.modelB.position.x.max *
          this.settings.mouse.strength,
        this.settings.modelB.position.x.min
      ),
      this.settings.mouse.speed
    )

    this.modelB.position.y = -THREE.MathUtils.lerp(
      Math.abs(this.modelB.position.y),
      Math.max(
        this.settings.modelB.position.y.min +
          absMouseX *
            this.settings.modelB.position.y.max *
            this.settings.mouse.strength,
        this.settings.modelB.position.y.min
      ),
      this.settings.mouse.speed
    )

    // Update lights position

    if (this.useMouse) {
      this.PointLightA.position.x +=
        (-this.settings.mouse.x * 5 -
          this.PointLightA.position.x +
          this.PointLightAStart.x) *
        this.settings.mouse.speed

      this.PointLightB.position.x +=
        (this.settings.mouse.x * 5 -
          this.PointLightB.position.x +
          this.PointLightBStart.x) *
        this.settings.mouse.speed
    }
  }

  handleUserEvents() {
    this.onPointerMove = event => {
      if (event.isPrimary === false) return

      const xMin = this.props.useWindow
        ? 0
        : this.wrapper.getBoundingClientRect().left
      const xMax = this.props.useWindow
        ? window.innerWidth
        : xMin + this.wrapper.offsetWidth
      const yMin = this.props.useWindow
        ? 0
        : this.wrapper.getBoundingClientRect().top
      const yMax = this.props.useWindow
        ? window.innerHeight
        : yMin + this.wrapper.offsetHeight

      this.settings.mouse.x =
        gsap.utils.normalize(xMin, xMax, event.clientX) * 2 - 1
      this.settings.mouse.y =
        gsap.utils.normalize(yMin, yMax, event.clientY) * 2 - 1
    }

    this.wrapper.addEventListener('pointermove', this.onPointerMove)
  }

  setSizes() {
    this.sizes = {
      width: this.DOM.wrapper.current.offsetWidth,
      height: this.DOM.wrapper.current.offsetHeight,
    }
    this.aspectRatio = this.sizes.height / this.sizes.width

    this.resizeHandler = () => {
      // Update sizes
      this.sizes.width = this.DOM.wrapper.current.offsetWidth
      this.sizes.height = this.DOM.wrapper.current.offsetHeight
      this.aspectRatio = this.sizes.height / this.sizes.width

      // Update camera
      this.updateCamera()

      // Update renderer
      this.renderer.setSize(this.sizes.width, this.sizes.height)
      this.renderer.setPixelRatio(this.getPixelRatio())
    }

    window.addEventListener('resize', this.resizeHandler)

    try {
      this.pixelRatioMatch.addEventListener('change', this.resizeHandler)
    } catch (error) {
      try {
        this.pixelRatioMatch.addListener(this.resizeHandler)
      } catch (error2) {
        console.error(error2)
      }
    }
  }

  updateCamera() {
    const scaleFactor = this.sizes.width > 1240 ? this.sizes.width / 1240 : 1
    const size = 1

    this.camera.left = -size * scaleFactor
    this.camera.right = size * scaleFactor
    this.camera.top = size * this.aspectRatio * scaleFactor
    this.camera.bottom = -size * this.aspectRatio * scaleFactor
    this.camera.zoom = 0.12
    this.camera.updateProjectionMatrix()
  }

  setCamera() {
    this.camera = new THREE.OrthographicCamera()
    this.updateCamera()
    this.camera.near = 0
    this.camera.far = 30
    this.camera.position.z = 10
    this.camera.lookAt(this.scene.position)
    this.scene.add(this.camera)
  }

  setControls() {
    this.controls = new OrbitControls(this.camera, this.DOM.canvas.current)
    this.controls.enableDamping = true
    this.controls.enableZoom = false
    this.controls.dampingFactor = 0.05
  }

  setLights() {
    this.ambientLight = new THREE.AmbientLight()
    this.ambientLight.color = new THREE.Color(0xffffff)
    this.ambientLight.intensity = 1
    this.scene.add(this.ambientLight)

    this.PointLightAStart = {
      x: 9,
      y: -7.2,
      z: 0.9,
    }
    this.PointLightA = new THREE.PointLight(0xa6f9ff, 1.5)
    this.PointLightA.visible = true
    this.PointLightA.position.set(
      this.PointLightAStart.x,
      this.PointLightAStart.y,
      this.PointLightAStart.z
    )
    this.scene.add(this.PointLightA)

    this.PointLightBStart = {
      x: 0.5,
      y: 6,
      z: -0.1,
    }
    this.PointLightB = new THREE.PointLight(0x8c69ff, 1.5)
    this.PointLightB.visible = true
    this.PointLightB.position.set(
      this.PointLightBStart.x,
      this.PointLightBStart.y,
      this.PointLightBStart.z
    )
    this.scene.add(this.PointLightB)

    this.SpotLightA = new THREE.SpotLight(0xffffff, 1)
    this.SpotLightA.visible = true
    this.SpotLightA.position.set(20, 3, 2)
    this.SpotLightA.distance = 20
    this.SpotLightA.penumbra = 1
    this.SpotLightA.angle = THREE.MathUtils.degToRad(30)
    this.scene.add(this.SpotLightA)

    this.SpotLightB = new THREE.SpotLight(0xffffff, 2)
    this.SpotLightB.visible = true
    this.SpotLightB.position.set(-30, -16, 30)
    this.SpotLightB.distance = 30
    this.SpotLightB.penumbra = 1
    this.SpotLightB.angle = THREE.MathUtils.degToRad(30)
    this.scene.add(this.SpotLightB)

    if (this.debug) {
      this.PointLightAHelper = new THREE.PointLightHelper(this.PointLightA)
      this.PointLightAHelper.visible = false
      this.scene.add(this.PointLightAHelper)

      this.PointLightBHelper = new THREE.PointLightHelper(this.PointLightB)
      this.PointLightBHelper.visible = false
      this.scene.add(this.PointLightBHelper)

      this.SpotLightAHelper = new THREE.SpotLightHelper(this.SpotLightA)
      this.SpotLightAHelper.visible = false
      this.scene.add(this.SpotLightAHelper)

      this.SpotLightBHelper = new THREE.SpotLightHelper(this.SpotLightB)
      this.SpotLightBHelper.visible = false
      this.scene.add(this.SpotLightBHelper)
    }
  }

  loadModels() {
    const gltfLoader = new GLTFLoader(this.loadingManager)

    gltfLoader.load('/models/mono-reduced.gltf', gltf => {
      this.geometryA = this.track(gltf.scene.children[0].children[0].geometry)
      this.geometryB = this.track(gltf.scene.children[0].children[1].geometry)
      this.material = this.track(
        new THREE.MeshPhysicalMaterial({
          color: colors.dark,
          metalness: 0.44,
          roughness: 0.28,
          clearcoat: 0.62,
          clearcoatRoughness: 0.19,
          transparent: true,
          // opacity: 0,
        })
      )

      // Model A
      const meshA = this.track(new THREE.Mesh(this.geometryA, this.material))
      meshA.scale.set(0.01, 0.01, 0.01)

      const meshABox = new THREE.Box3().setFromObject(meshA)
      meshABox.getCenter(meshA.position)

      meshA.position.multiplyScalar(-1)

      this.modelA = new THREE.Group()
      this.modelA.position.set(
        this.settings.modelA.position.x.min,
        this.settings.modelA.position.y.min,
        this.settings.modelA.position.z
      )
      this.modelA.rotateX(THREE.Math.degToRad(30))
      this.modelA.rotateY(THREE.Math.degToRad(-45))
      this.modelA.add(meshA)

      // const boxAHelper = new THREE.BoxHelper(meshA, 0xffff00)
      // this.modelA.add(boxAHelper)

      this.scene.add(this.modelA)

      // Model B
      const meshB = this.track(new THREE.Mesh(this.geometryB, this.material))
      meshB.scale.set(0.01, 0.01, 0.01)
      const modelBBox = new THREE.Box3().setFromObject(meshB)

      modelBBox.getCenter(meshB.position)
      meshB.position.multiplyScalar(-1)

      this.modelB = new THREE.Group()
      this.modelB.position.set(
        this.settings.modelB.position.x.min,
        -this.settings.modelB.position.y.min,
        this.settings.modelB.position.z
      )
      this.modelB.rotateX(THREE.Math.degToRad(30))
      this.modelB.rotateY(THREE.Math.degToRad(-45))
      this.modelB.add(meshB)

      // const boxB = new THREE.BoxHelper(meshB, 0xff0000)
      // this.modelB.add(boxB)

      this.scene.add(this.modelB)
    })
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.DOM.canvas.current,
      antialias: window.devicePixelRatio > 1 ? false : true,
    })
    this.renderer.autoClear = false
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(this.getPixelRatio())
  }

  renderLoop(time) {
    if (this.stats) this.stats.update()

    if (this.modelsLoaded) this.updateScene(time)
    if (this.debug) this.controls.update()
    this.renderer.render(this.scene, this.camera)
    this.raf = window.requestAnimationFrame(this.renderLoop.bind(this))
  }

  render() {
    const refs = this.DOM

    return (
      <>
        <SceneWrapper ref={refs.wrapper}>
          <SceneCanvas ref={refs.canvas} />
        </SceneWrapper>
      </>
    )
  }
}

export default SceneOrthographic

SceneOrthographic.propTypes = {
  useWindow: PropTypes.bool,
  debug: PropTypes.bool,
}

SceneOrthographic.defaultProps = {
  useWindow: true,
  debug: false,
}
