import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { clamp } from '../../../styles/utils/conversion.style'
import { grid } from '../../../styles/vars/grid.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { AnimateSlideInMask } from '../../animation/AnimateSlideIn/index.style'

export const HomeStepsWrapper = styled.div`
  overflow: hidden;
`

export const HomeStepsOuter = styled.div``

export const HomeStepsTitle = styled.div`
  text-align: center;
`

export const HomeStepsContainer = styled.div``

export const HomeStepsMain = styled.div`
  display: flex;
  width: fit-content;
`

export const HomeStepsSlide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${props => `${props.width / 10}rem`};
  ${clamp('padding-top', 40, 142)}
  ${clamp('padding-bottom', 40, 142)}
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  overflow: hidden;
  background-color: ${colors.light};
  ${clamp('border-radius', 16, 24)}
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${mq.tabletP} {
    padding-left: 4.2rem;
    padding-right: 4.2rem;
  }

  ${props =>
    props.firstSlide ? `` : clamp('margin-left', grid.gap.min, grid.gap.max)}
`

export const HomeStepsLabel = styled.div`
  position: absolute;
  ${clamp('left', 28, 50, breakpoints.tabletP)}
  ${clamp('bottom', 32, 70, breakpoints.tabletP)}
  transform-origin: 0% 0%;
  transform: translateY(100%) rotate(-90deg);
  display: none;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.2s ease;

  ${mq.tabletP} {
    display: block;
  }
`

export const HomeStepsDots = styled.div`
  text-align: center;
`

export const HomeStepContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  p {
    > span {
      display: inline-block;
    }
  }
`

export const HomeStepImage = styled.div`
  max-width: 23rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.6rem;
  pointer-events: none;

  ${mq.tabletP} {
    max-width: none;
    margin-bottom: 0;
  }

  ${AnimateSlideInMask} {
    display: flex;
    align-items: center;
    height: 100%;

    span {
      height: 100%;
      width: 100%;
    }
  }
`
