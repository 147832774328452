import React from 'react'
import PropTypes from 'prop-types'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import {
  HomeBenefitsHeader,
  HomeBenefitsIcon,
  HomeBenefitsImage,
  HomeBenefitsImageWrapper,
  HomeBenefitsList,
  HomeBenefitsListIcon,
  HomeBenefitsListItem,
  HomeBenefitsMain,
} from './index.style'
import Spacer from '../../Spacer'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { Heading3, TextBody, TextBodyBold } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import Monogram from '../../svgs/Monogram'
import { GatsbyImage } from 'gatsby-plugin-image'
import IconTick from '../../svgs/IconTick'

const HomeBenefits = ({ title, text, label, image, items }) => (
  <ScrollSection>
    <Container>
      <Spacer size={[64, 160]} />

      <Grid>
        <GridItem tabletP={10} tabletPStart={2} desk={6} deskStart={4}>
          <HomeBenefitsHeader>
            <AnimateSlideIn>
              <HomeBenefitsIcon>
                <Monogram responsive />
              </HomeBenefitsIcon>
            </AnimateSlideIn>

            <Spacer size={38} />

            <Heading3 as="h3">
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>
          </HomeBenefitsHeader>

          <Spacer size={[66, 96]} />
        </GridItem>

        <GridItem tabletP={4} tabletPStart={2} desk={3} deskStart={3}>
          <HomeBenefitsImageWrapper>
            <AnimateSlideIn>
              <HomeBenefitsImage>
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.description}
                />
              </HomeBenefitsImage>
            </AnimateSlideIn>
          </HomeBenefitsImageWrapper>
        </GridItem>

        <GridItem tabletP={6} tabletPStart={6} desk={4} deskStart={6}>
          <HomeBenefitsMain>
            <TextBody>
              <AnimateSplitText>{text}</AnimateSplitText>
            </TextBody>
            <br />
            <TextBodyBold>
              <AnimateSplitText>{label}</AnimateSplitText>
            </TextBodyBold>

            <Spacer size={18} />

            <HomeBenefitsList>
              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <HomeBenefitsListItem>
                    <TextBody>
                      <HomeBenefitsListIcon>
                        <AnimateSlideIn delay={itemIndex * 0.1}>
                          <IconTick responsive />
                        </AnimateSlideIn>
                      </HomeBenefitsListIcon>
                      <AnimateSplitText delay={itemIndex * 0.1}>
                        {item}
                      </AnimateSplitText>
                    </TextBody>
                  </HomeBenefitsListItem>
                ))
              )}
            </HomeBenefitsList>
          </HomeBenefitsMain>
        </GridItem>
      </Grid>

      <Spacer size={[110, 240]} />
    </Container>
  </ScrollSection>
)

HomeBenefits.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
}

export default HomeBenefits
