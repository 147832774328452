import React from 'react'
import PropTypes from 'prop-types'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading3, TextBody } from '../../TextStyles'
import Spacer from '../../Spacer'
import Cta from '../../Cta'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { HomeCompareMain } from './index.style'
import ScrollSection from '../../ScrollSection'
import Table from '../../Table'
import { colors } from '../../../styles/vars/colors.style'

const HomeCompare = ({ title, text, linkText, link, table }) => (
  <ScrollSection>
    <Container>
      <Spacer size={[70, 300]} />

      <Grid>
        <GridItem tabletL={4} deskL={3} deskLStart={2}>
          <HomeCompareMain>
            <Heading3 as="h3" color={colors.blue}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>

            <Spacer size={[26, 35]} />

            <TextBody>
              <AnimateSplitText>{text}</AnimateSplitText>
            </TextBody>

            <Spacer size={[38, 51]} />

            <Cta to={link} label={linkText} color={colors.blue} />
          </HomeCompareMain>
        </GridItem>

        <GridItem tabletL={8} desk={7} deskStart={6} deskL={6} deskLStart={6}>
          <Table data={table} />
        </GridItem>
      </Grid>

      <Spacer size={[120, 300]} />
    </Container>
  </ScrollSection>
)

HomeCompare.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  table: PropTypes.array,
}

export default HomeCompare
