import styled, { css } from 'styled-components'
import StickerBackground from './images/sticker-background.jpg'
import TextSVG from './images/sticker-content.svg'
import { transparentize } from 'polished'

const lightColor = '#EFF7FB'
const rotateMax = 18
const fix = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const StickerWrapper = styled.div`
  width: 100%;
  height: 100%;
  perspective: 100rem;
`

export const StickerMain = styled.div.attrs(({ offset, active }) => {
  const transform = `rotateX(${-offset[1] * rotateMax}deg) rotateY(${
    -offset[0] * rotateMax
  }deg)`
  const transition = active ? 'transform 0.1s linear' : 'transform 0.5s ease'

  return {
    style: {
      transform,
      transition,
    },
  }
})`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: black;
  background-image: url(${StickerBackground});
  background-size: contain;
  will-change: transform;
`

export const StickerContent = styled.div`
  ${fix}
  background-image: url(${TextSVG});
  background-size: contain;
  background-repeat: no-repeat;
`

export const StickerLight = styled.div.attrs(({ offset, active }) => {
  const backgroundPosition = active ? `${offset[0] * -100}% 0%` : undefined

  return {
    style: {
      opacity: active ? 1 : 0,
      backgroundPosition,
    },
  }
})`
  ${fix}
  background: linear-gradient(54deg,
  ${transparentize(1, lightColor)} 42%,
  ${transparentize(0.8, lightColor)} 47%,
  ${transparentize(0.7, lightColor)} 50%,
  ${transparentize(0.8, lightColor)} 53%,
  ${transparentize(1, lightColor)} 58% );
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: opacity 0.2s ease;
`
