import React from 'react'
import PropTypes from 'prop-types'
import {
  HomeContactForm,
  HomeContactMain,
  HomeContactMonogram,
} from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import { Heading3 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Monogram from '../../svgs/Monogram'
import ContactForm from '../../ContactForm'

const HomeContact = ({ title }) => (
  <ScrollSection>
    <HomeContactMain>
      <HomeContactMonogram>
        <Monogram responsive />
      </HomeContactMonogram>

      <Container>
        <Spacer size={[50, 90]} />

        <Grid>
          <GridItem tabletL={10} tabletLStart={2} deskL={6} deskLStart={4}>
            <Heading3 as="h3">
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>

            <Spacer size={[34, 40]} />

            <HomeContactForm>
              <ContactForm />
            </HomeContactForm>
          </GridItem>
        </Grid>

        <Spacer size={[50, 90]} />
      </Container>
    </HomeContactMain>
  </ScrollSection>
)

HomeContact.propTypes = {
  title: PropTypes.string,
}

export default HomeContact
