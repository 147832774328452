import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'

export const HomeTabsMain = styled.div`
  text-align: center;
  color: ${colors.light};
`

export const HomeTabsContent = styled.div`
  overflow: hidden;
  background-color: ${colors.darkGrey};
`

export const HomeTabsList = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 0.1rem solid ${transparentize(0.7, colors.midGrey)};
`

export const HomeTabsActive = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 0.1rem;
  background-color: ${colors.light};
`

export const HomeTabsButton = styled.button`
  padding: 2.8rem 1.6rem;
  color: ${props => (props.open ? colors.lightGrey : colors.midGrey)};
  cursor: pointer;
  border: none;
  background: ${colors.darkGrey};
  transition: color 0.2s ease;
  outline: none;
`

export const HomeTabsItems = styled.div`
  position: relative;
`

export const HomeTabsTestimonial = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: ${props => (props.active ? undefined : 'none')};

  :first-child {
    position: relative;
  }
`
