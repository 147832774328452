import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { clamp, getClampValue } from '../../../styles/utils/conversion.style'
import { GridMain } from '../../Grid/index.style'
import { StickerWrapper } from '../../Sticker/index.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import {
  AnimateSlideInContent,
  AnimateSlideInMask,
} from '../../animation/AnimateSlideIn/index.style'

export const HomeJoinMain = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.lightGrey};
  border-radius: 2.4rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-color: ${colors.blue};

  ${GridMain} {
    align-items: stretch;
  }

  .gatsby-image-wrapper {
    overflow: hidden;
    border-radius: 1rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

export const HomeJoinHeader = styled.div`
  position: relative;

  ${mq.tabletL} {
    height: 100%;
    ${clamp(
      'padding-left',
      24,
      48,
      breakpoints.tabletL,
      breakpoints.contained,
      false
    )}
    ${clamp(
      'padding-right',
      24,
      48,
      breakpoints.tabletL,
      breakpoints.contained,
      false
    )}
  }

  ${StickerWrapper} {
    position: absolute;
  }
`

export const HomeJoinContent = styled.div`
  ${mq.tabletLMax} {
    ${clamp(
      'padding-left',
      24,
      48,
      breakpoints.mobile,
      breakpoints.tabletL,
      false
    )}
    ${clamp(
      'padding-right',
      24,
      48,
      breakpoints.mobile,
      breakpoints.tabletL,
      false
    )}
  }

  ${mq.tabletL} {
    position: relative;
  }
`

export const HomeJoinImages = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  ${clamp('grid-gap', 14, 48, breakpoints.mobile, breakpoints.deskL, false)}
  height: 100%;

  ${mq.tabletLMax} {
    ${clamp('left', 24, 48, breakpoints.mobile, breakpoints.tabletL)}
  }

  ${mq.tabletL} {
    grid-template-columns: 1fr 1.6fr;
  }
`

export const HomeJoinImageColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  :nth-child(1) {
    ${mq.tabletL} {
      grid-column-start: 2;
    }
  }

  :nth-child(2) {
    ${mq.tabletLMax} {
      ${clamp('bottom', 56, 192)}
    }

    ${mq.tabletL} {
      ${clamp('top', -1, -130, breakpoints.tabletL, breakpoints.contained)}
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row: 1;
      grid-column-start: 1;
    }
  }
`

export const HomeJoinImage = styled.div`
  position: relative;
  width: 100%;

  ${mq.tabletP} {
    top: -30vh;
  }

  ${mq.tabletL} {
    top: -23vh;
  }

  ${props => {
    if (props.index === 0) {
      return `
        ${mq.tabletL} {
          top: -10vh;
        }
      `
    }

    if (props.index === 3) {
      return `
        ${mq.tabletL} {
          top: -12vh;
        }
      `
    }
  }}

  & + & {
    ${clamp('margin-top', 14, 48)}
  }
`

export const HomeJoinImageWrap = styled.div``

export const HomeJoinDesktopImage = styled.div`
  position: absolute;
  left: calc(100% + ${getClampValue(4, 120)});
  ${clamp('bottom', -100, -48, breakpoints.tabletL)}
  display: none;
  ${clamp('width', 200, 284, breakpoints.tabletL)}

  ${mq.tabletL} {
    display: block;
  }
`

export const HomeJoinSticker = styled.div`
  ${AnimateSlideInMask} {
    position: absolute;
    transform: translate(-50%, 50%);
    ${clamp('width', 90, 110, breakpoints.tabletL, breakpoints.deskL, false)}
    ${clamp('height', 90, 110, breakpoints.tabletL, breakpoints.deskL, false)}
  
  ${mq.tabletLMax} {
      bottom: 0;
      left: 0;
    }

    ${mq.tabletL} {
      top: 0;
      right: 0;
      transform: translate(40%, 70%);
    }
  }

  ${AnimateSlideInContent} {
    width: 100%;
    height: 100%;
  }
`
