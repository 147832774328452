import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { InView } from 'react-intersection-observer'
import { gsap, Draggable, InertiaPlugin } from 'gsap/all'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components

import AnimateSlideIn from '../../animation/AnimateSlideIn'
import RichText from '../../RichText'
import Container from '../../Container'
import ScrollSection from '../../ScrollSection'
import Spacer from '../../Spacer'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import VimeoPlayer from '../../VimeoPlayer'
import CarouselDots from '../../CarouselDots'
import Cta from '../../Cta'
import AnimateSplitText from '../../animation/AnimateSplitText'

// Styles

import {
  HomeStepContent,
  HomeStepImage,
  HomeStepsContainer,
  HomeStepsDots,
  HomeStepsLabel,
  HomeStepsMain,
  HomeStepsOuter,
  HomeStepsSlide,
  HomeStepsTitle,
  HomeStepsWrapper,
} from './index.style'
import { Title, TextBody, Heading2 } from '../../TextStyles'
import { colors } from '../../../styles/vars/colors.style'
import { StoreDispatch } from '../../../Store'

const HomeSteps = ({ title, steps, route, routeTitle }) => {
  const dispatch = useContext(StoreDispatch)
  const outerRef = useRef()
  const wrapperRef = useRef()
  const dragRef = useRef()
  const marginRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [stepWidth, setStepWidth] = useState(272)
  const [widthIsSet, setWidthIsSet] = useState(false)

  const getSlideMargin = useCallback(() => {
    return parseInt(window.getComputedStyle(marginRef.current).marginLeft, 10)
  }, [])

  const getMinXBound = useCallback(
    () => -dragRef.current.offsetWidth + outerRef.current.offsetWidth,
    []
  )

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)

    Draggable.create(dragRef.current, {
      type: 'x',
      edgeResistance: 0.9,
      inertia: true,
      cursor: 'none',
      throwProps: true,
      minDuration: 0.2,
      maxDuration: 1,
      snap: endValue => {
        const snapIncrement = wrapperRef.current.offsetWidth + getSlideMargin()

        setActiveIndex(Math.abs(Math.round(endValue / snapIncrement)))
        return Math.round(endValue / snapIncrement) * snapIncrement
      },
    })

    const applyDraggableBounds = () => {
      Draggable.get(dragRef.current).applyBounds({
        minX: getMinXBound(),
        maxX: 0,
      })
    }

    const handleResize = debounce(() => {
      if (wrapperRef.current) {
        setStepWidth(wrapperRef.current.offsetWidth)
        setWidthIsSet(true)
        applyDraggableBounds()
      }
    }, 100)

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [getSlideMargin, getMinXBound])

  const paginateCarousel = () => {
    const xOffset =
      -(activeIndex + 1) * (wrapperRef.current.offsetWidth + getSlideMargin())

    gsap.to(dragRef.current, {
      x: Math.max(xOffset, getMinXBound()),
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => setActiveIndex(activeIndex + 1),
    })
  }

  return (
    <ScrollSection>
      <HomeStepsWrapper>
        <Container allowOverflow>
          <HomeStepsOuter ref={outerRef}>
            <HomeStepsTitle>
              <Heading2 color={colors.blue}>
                <AnimateSplitText readyToSplit={widthIsSet}>
                  {title}
                </AnimateSplitText>
              </Heading2>

              <Spacer size={[24, 70]} />
            </HomeStepsTitle>

            <Grid>
              <GridItem tabletP={11}>
                <HomeStepsContainer ref={wrapperRef}>
                  <HomeStepsMain
                    ref={dragRef}
                    onMouseEnter={() => dispatch({ type: 'SHOW_CURSOR' })}
                    onMouseLeave={() => dispatch({ type: 'HIDE_CURSOR' })}
                  >
                    {React.Children.toArray(
                      steps.map((step, stepIndex) => (
                        <HomeStepsSlide
                          width={stepWidth}
                          ref={stepIndex === 1 ? marginRef : undefined}
                          firstSlide={stepIndex === 0}
                        >
                          {stepIndex > 0 && (
                            <HomeStepsLabel
                              aria-hidden
                              show={stepIndex !== activeIndex}
                              data-inview={stepIndex !== activeIndex}
                            >
                              <Title>{step.stepNumber}</Title>
                              <Spacer size={[0, 8]} />
                              <RichText
                                readyToSplit={widthIsSet}
                                content={step.title1}
                                heading3
                              />
                            </HomeStepsLabel>
                          )}

                          <InView threshold={0.6}>
                            {({ inView, ref }) => (
                              <div ref={ref} data-inview={inView.toString()}>
                                <Grid colCount={11}>
                                  <GridItem
                                    tabletP={4}
                                    tabletPStart={8}
                                    desk={4}
                                    deskStart={7}
                                  >
                                    <HomeStepImage>
                                      <AnimateSlideIn>
                                        {step.videoId ? (
                                          <VimeoPlayer
                                            id={step.videoId}
                                            autoplay
                                            controls={false}
                                            playState={
                                              inView &&
                                              stepIndex === activeIndex
                                                ? true
                                                : false
                                            }
                                            muted
                                            loop
                                          />
                                        ) : (
                                          <GatsbyImage
                                            image={step.image.gatsbyImageData}
                                            alt=""
                                            objectFit="contain"
                                          />
                                        )}
                                      </AnimateSlideIn>
                                    </HomeStepImage>
                                  </GridItem>

                                  <GridItem
                                    tabletP={7}
                                    tabletPStart={1}
                                    tabletL={6}
                                    desk={5}
                                    deskStart={2}
                                    deskL={4}
                                    deskLStart={2}
                                  >
                                    <HomeStepContent>
                                      <Title>
                                        <AnimateSlideIn
                                          animate={stepIndex === activeIndex}
                                        >
                                          {step.stepNumber}
                                        </AnimateSlideIn>
                                      </Title>
                                      <Spacer size={[4, 13]} />
                                      <RichText
                                        readyToSplit={widthIsSet}
                                        content={step.title1}
                                        heading1
                                        gradient
                                        animate={stepIndex === activeIndex}
                                      />
                                      <Spacer size={[26, 31]} />
                                      <TextBody>
                                        <AnimateSplitText
                                          readyToSplit={widthIsSet}
                                          type="lines,words"
                                          animate={stepIndex === activeIndex}
                                        >
                                          {step.text.text}
                                        </AnimateSplitText>
                                      </TextBody>
                                      {step.nextStepText && (
                                        <>
                                          <Spacer size={[27, 57]} />
                                          <Cta
                                            onClick={paginateCarousel}
                                            label={step.nextStepText}
                                            color={colors.blue}
                                            button
                                            animate={stepIndex === activeIndex}
                                            onMouseEnter={() =>
                                              dispatch({ type: 'HIDE_CURSOR' })
                                            }
                                            onMouseLeave={() =>
                                              dispatch({ type: 'SHOW_CURSOR' })
                                            }
                                          />
                                        </>
                                      )}
                                      {stepIndex === steps.length - 1 && (
                                        <>
                                          <Spacer size={[27, 57]} />
                                          <Cta
                                            to={route}
                                            label={routeTitle}
                                            color={colors.blue}
                                            animate={stepIndex === activeIndex}
                                            onMouseEnter={() =>
                                              dispatch({ type: 'HIDE_CURSOR' })
                                            }
                                            onMouseLeave={() =>
                                              dispatch({ type: 'SHOW_CURSOR' })
                                            }
                                          />
                                        </>
                                      )}
                                    </HomeStepContent>
                                  </GridItem>
                                </Grid>
                              </div>
                            )}
                          </InView>
                        </HomeStepsSlide>
                      ))
                    )}
                  </HomeStepsMain>
                </HomeStepsContainer>
              </GridItem>
            </Grid>

            <Spacer size={[24, 48]} />

            <HomeStepsDots>
              <AnimateSlideIn>
                <CarouselDots
                  slides={steps}
                  activeIndex={activeIndex}
                  theme="dark"
                />
              </AnimateSlideIn>
            </HomeStepsDots>

            <Spacer size={[96, 240]} />
          </HomeStepsOuter>
        </Container>
      </HomeStepsWrapper>
    </ScrollSection>
  )
}

HomeSteps.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
}

export default HomeSteps
